import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const MoreD4ta: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article className="list-page">
      <Helmet>
        <title>{'MORE D4TA - EXCLUSIVE TICKET BUNDLES'}</title>
        <body className="mored4ta-listing-page" />
      </Helmet>

      <Container className="pt-3">
        <ContentrArea id="before-listing" />
      </Container>

      <Container className="list-page py-5">
        <div className="hero-slider">
          <img src={require('./assets/images/header-220208.png')} alt="More Data" className="img-fluid w-100 mb-5" />
        </div>

        <Row className="mb-4">
          <Col md="12">
            <h1 className="text-center">
              {'EXCLUSIVE TICKET BUNDLES'}
              <br />
              <small>{'Release Date 13.05.22'}</small>
            </h1>
          </Col>
        </Row>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            columnClassName="col-md-6 col-lg-6"
            products={products.filter(productFilter)}
            filters="off"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(MoreD4ta);
