import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductDetails } from '@ttstr/components/ProductDetail/ProductDetailsContext';

interface OwnProps {
  merchantId: number;
}

type Props = Readonly<OwnProps>;

const MerchantInfo: React.FC<Props> = ({ merchantId }) => {
  const { t } = useTranslation();
  const { merchantsReceived, merchants } = useProductDetails();

  if (!merchantsReceived) return null;

  const merchant = merchants[merchantId];

  if (!merchant) return null;

  return (
    <aside className="small">
      <span className="d-block mb-1">{t('PRODUCT.MERCHANT_INFO.TITLE')}</span>
      <address>
        {merchant.website ? (
          <a href={merchant.website} target="_blank" rel="noopener noreferrer">
            {merchant.title}
          </a>
        ) : (
          merchant.title + ', '
        )}
        {merchant.street && (
          <>
            {merchant.street + ', '}
            {merchant.zip_code} {merchant.city}
          </>
        )}
      </address>
      {/* <pre>{JSON.stringify(merchant, null, 2)}</pre> */}
    </aside>
  );
};

export default React.memo(MerchantInfo);
